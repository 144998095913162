import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import RoutesList from "../../../routes";
import { useDispatch, useSelector } from "react-redux";
import {
  jobApplicantTitle,
  jobPositionTitle,
  selectRoles,
  selectSelectedLocation,
  selectUser,
  setInitialChatFetch,
  setMinimizedPersons,
  setSelectedLocation,
  setSelectedPersons,
  setVisiblePersons,
} from "../../../store/authSlice";
import { encodeIds } from "../../../utils";

export default function BusinessBreadCrumbs({ darmo }) {
  const location = useLocation();
  const pathName = location.pathname;
  const params = useParams();
  const roles = useSelector(selectRoles);
  const positionTitle = useSelector(jobPositionTitle);
  const applicantName = useSelector(jobApplicantTitle);
  const typeManager = location.pathname.search("manage-users") !== -1;
  const selectLocation = useSelector(selectSelectedLocation);
  const isLocationSelected =
    selectLocation?.name && selectLocation.name !== "Select A Location";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isEnterprise = user?.company?.is_enterprise === 1;
  const showLocationDropdown =
    (isEnterprise && roles[0] === "owner") ||
    (isEnterprise && roles[0] !== "employee") ||
    roles[0] === "super-user" ||
    roles[0] === "regional_manager";

  if (pathName === "/dashboard") {
    return (
      <div className="flex items-center">
        <i className="text-lg far fa-home text-gray-900 dark:text-gray-500 mr-2" />
        <span className="text-base font-extrabold text-gray-600 dark:text-white">
          Home
        </span>
      </div>
    );
  }

  const crumbs = RoutesList.filter(({ path }) => {
    const pathRegex = new RegExp(`^${path.replace(/:\w+/g, "\\w+")}$`);
    return pathRegex.test(pathName);
  }).map(({ path, ...rest }) => ({
    path: Object.keys(params).length
      ? Object.keys(params).reduce(
          (path, param) => path.replace(`:${param}`, params[param]),
          path
        )
      : path,
    ...rest,
  }));

  if (crumbs.length <= 0) {
    return null;
  }

  if (crumbs[0]?.name === "Positions") {
    crumbs.push({
      name: positionTitle?.title,
      path: `/business/view-job/${encodeIds(positionTitle?.id)}`,
      current: true,
    });

    if (
      pathName.startsWith("/business/applicant/") ||
      pathName.startsWith("/business/hired-applicant/")
    ) {
      crumbs.push({
        name: applicantName,
        current: true,
      });
    }
  } else if (crumbs[0]?.name === "7Shifts") {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "Intergrations",
      path: "/intergrations",
      current: false,
    });
  } else if (
    crumbs[0]?.name === "Create Banner" ||
    crumbs[0]?.name === "Edit Banner"
  ) {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "QR Banners",
      path: "/business/posters",
      current: false,
    });
  } else if (
    crumbs[0]?.name === "Post Position" ||
    crumbs[0]?.name === "Edit Position"
  ) {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "Job Positions",
      path: "/job-positions",
      current: false,
    });
  } else if (
    crumbs[0]?.name === "Edit Messages" ||
    crumbs[0]?.name === "Add Message"
  ) {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "Canned Messages",
      path: "/messages/canned-messages",
      current: false,
    });
  } else if (crumbs[0]?.name === "Post Shift") {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "Shifts",
      path: "/shifts",
      current: false,
    });
  } else if (
    crumbs[0]?.name === "Edit Sub User" ||
    crumbs[0]?.name === "Add Sub User"
  ) {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "Manage Sub Users",
      path: typeManager ? "/business/manage-users" : "/business/sub-users",
      current: false,
    });
  } else if (
    crumbs[0]?.name === "Edit QR Code" ||
    crumbs[0]?.name === "Create QR Code"
  ) {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "QR Code",
      path: "/business/qr-code",
      current: false,
    });
  } else if (crumbs[0]?.name === "Job Applicants") {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "Analytics",
      path: "/analytics",
      current: false,
    });
  }

  const handleHomeClick = () => {
    localStorage.removeItem("selectedLocation");
    dispatch(
      setSelectedLocation({
        name: "Select A Location",
      })
    );
    navigate(`/dashboard`);
    dispatch(setInitialChatFetch(false));
    dispatch(setVisiblePersons([]));
    dispatch(setMinimizedPersons([]));
    dispatch(setSelectedPersons([]));
  };

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol
        role="list"
        className="flex overflow-x-auto whitespace-nowrap justify-center items-center"
      >
        <li className="flex-shrink-0">
          {isLocationSelected &&
          roles[0] !== "turnkey_admin" &&
          showLocationDropdown ? (
            <span
              className="hover:text-gray-500 flex items-center text-gray-600 dark:text-white cursor-pointer"
              onClick={handleHomeClick}
            >
              <i className="text-lg far fa-home text-gray-900 dark:text-gray-500 mr-2" />
              <span className="text-sm font-extrabold text-gray-600 dark:text-white">
                Home
              </span>
            </span>
          ) : (
            <Link
              to="/dashboard"
              className="hover:text-gray-500 flex items-center text-gray-600 dark:text-white"
            >
              <i className="text-lg far fa-home text-gray-900 dark:text-gray-500 mr-2" />
              <span className="text-sm font-extrabold text-gray-600 dark:text-white">
                Home
              </span>
            </Link>
          )}
        </li>

        {isLocationSelected &&
          roles[0] !== "turnkey_admin" &&
          showLocationDropdown && (
            <>
              <li>
                <svg
                  className="h-5 w-5 flex-shrink-0 text-gray-600 dark:text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
              </li>
              <li
                className="text-sm font-extrabold text-gray-600 dark:text-white cursor-pointer hover:underline"
                onClick={() => {
                  if (pathName !== "/business/dashboard") {
                    navigate("/business/dashboard");
                  }
                }}
              >
                {selectLocation.name}
              </li>
            </>
          )}

        {crumbs?.map((crumb, index) => (
          <li key={crumb.name}>
            <div className="flex items-center">
              <svg
                className={`h-5 w-5 flex-shrink-0 ${
                  darmo ? "text-gray-600 dark:text-gray-500" : "text-white"
                }`}
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <Link
                to={crumb.path}
                className={`text-sm font-extrabold ${
                  darmo ? "" : "text-white"
                } ${
                  index === crumbs.length - 1
                    ? "text-primary-500 dark:text-gray-500"
                    : "text-gray-600 dark:text-white"
                } hover:text-gray-500`}
                aria-current={crumb.current ? "page" : undefined}
              >
                {crumb.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
