import axios from "./api";

const base = "/business";

const BusinessAnalyticsDashboard = {
  getBusinessAnalytics(id) {
    return axios.get(`${base}/analytics/${id}`);
  },
  getAnalyticsCount(id) {
    return axios.get(`${base}/job-stats-count/${id}`);
  },
  getApplicantDetails(id) {
    return axios.get(`${base}/job-stats/${id}`);
  },
};

export default BusinessAnalyticsDashboard;
