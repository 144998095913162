import React, { useEffect, useRef } from "react";
import Chart from "react-apexcharts";

const JobPieChart = ({
  data,
  statCount,
  isInView,
  setIsInView,
  ongoingChats,
  mode = false,
  conversationLoading,
}) => {
  const chartRef = useRef(null);

  const calculateStatistics = () => {
    if (
      !data ||
      data?.length === 0 ||
      !ongoingChats ||
      ongoingChats?.length === 0
    )
      return {
        hiredCount: 0,
        chatConversationCount: 0,
        noChatConversationCount: 0,
        total: 0,
      };

    const hiredCount = statCount?.total_hired;

    // Get unique job seekers with "applied" status
    const appliedJobSeekers = new Set(
      data
        .filter((item) => item.job_seeker.status === "applied")
        .map((item) => item.job_seeker.job_seeker_id)
    );

    // Count conversations
    let chatConversationCount = 0;
    let noChatConversationCount = 0;

    appliedJobSeekers?.forEach((jobSeekerId) => {
      if (ongoingChats?.some((chat) => chat?.job_seeker_id === jobSeekerId)) {
        chatConversationCount++;
      } else {
        noChatConversationCount++;
      }
    });

    return {
      hiredCount,
      chatConversationCount,
      noChatConversationCount,
      total: hiredCount + chatConversationCount + noChatConversationCount,
    };
  };

  useEffect(() => {
    // Intersection Observer setup
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (chartRef.current) {
      observer.observe(chartRef.current);
    }

    return () => {
      if (chartRef.current) observer.unobserve(chartRef.current);
    };
  }, []);

  const stats = calculateStatistics();
  const total =
    stats.hiredCount +
    stats.chatConversationCount +
    stats.noChatConversationCount;

  const chartOptions = {
    colors: ["#594A9E", "#AA73A6", "#FFC9F1"],
    chart: {
      type: "pie",
      background: mode ? "transparent" : "#242526",
      foreColor: mode ? "#000000" : "#ffffff",
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.25,
        },
      },
    },
    stroke: {
      colors: ["white"],
      lineCap: "",
    },
    plotOptions: {
      pie: {
        labels: {
          show: true,
        },
        size: "100%",
        dataLabels: {
          offset: -25,
        },
      },
    },
    labels: ["Hired", "Conversation Started", "Conversation Not Started"],
    tooltip: {
      y: {
        formatter: function (value) {
          const percentage = ((value / total) * 100).toFixed(1);
          return `${value} (${percentage}%)`;
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontFamily: "Inter, sans-serif",
      },
    },
    legend: {
      position: "bottom",
      fontFamily: "Inter, sans-serif",
      labels: {
        colors: mode ? "#000000" : "#FFFFFF",
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
  };

  const chartSeries = [
    stats.hiredCount,
    stats.chatConversationCount,
    stats.noChatConversationCount,
  ];

  return (
    <div className="p-6 lg:col-span-2 relative overflow-hidden rounded-lg px-4 pb-12 pt-5 dark:bg-[#242526] shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1),0_2px_4px_-1px_rgba(0,0,0,0.06)] border border-warmgray-200 dark:border-darkGray dark:shadow-none sm:px-6 sm:pt-6 bg-transparent">
      <div className="flex justify-between mb-4 items-start">
        <div className="font-bold text-base dark:text-gray-300">
          HeyHire Statistics - Job Title
        </div>
        <div className="dropdown">
          <button
            type="button"
            className="dropdown-toggle text-gray-400 hover:text-gray-600"
          >
            <i className="ri-more-fill" />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
        <div className="rounded-md border border-dashed border-gray-200 p-4">
          <div className="flex items-center mb-0.5">
            <div className="text-xl font-bold dark:text-gray-300">
              {stats.hiredCount}
            </div>
          </div>
          <span className="text-gray-500 text-sm">Hired</span>
        </div>
        <div className="rounded-md border border-dashed border-gray-200 p-4">
          <div className="flex items-center mb-0.5">
            <div className="text-xl font-bold dark:text-gray-300">
              {stats.chatConversationCount}
            </div>
          </div>
          <span className="text-gray-500 text-sm">Conversation Started</span>
        </div>
        <div className="rounded-md border border-dashed border-gray-200 p-4">
          <div className="flex items-center mb-0.5">
            <div className="text-xl font-bold dark:text-gray-300">
              {stats.noChatConversationCount}
            </div>
          </div>
          <span className="text-gray-500 text-sm">
            Conversation Not Started
          </span>
        </div>
      </div>
      <div ref={chartRef}>
        {isInView &&
          !conversationLoading &&
          chartSeries &&
          !chartSeries.every((value) => value === 0) && (
            <Chart
              options={chartOptions}
              series={chartSeries}
              type="pie"
              height={420}
            />
          )}
      </div>
    </div>
  );
};

export default JobPieChart;
