/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  arrowDownWhiteIcon,
  defaultUserImage,
  heyhireBlackIcon,
  heyhireIcon,
  heyhireSidebar,
  heyhireSidebarWhite,
  intergrationsIcon,
  intergrationsWhiteIcon,
  locationTag1Icon,
  locationWhiteIcon,
  shiftsIcon,
  shiftsWhiteIcon,
} from "../../../assets/images";
import "./BusinessSidebar.scss";
import Location from "../../../api/Location";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedLocation,
  selectPermissions,
  selectRoles,
  selectSelectedLocation,
  selectUser,
  sidebar,
  setSidebarOpen,
  selectProprietor,
  setProprietor,
  logout,
  setInitialChatFetch,
  setVisiblePersons,
  setMinimizedPersons,
  setSelectedPersons,
  setRoleName,
  roleName,
  locations,
  setAnalytics,
  isAnalytics,
  darkmode,
  setBackgroundcolor,
} from "../../../store/authSlice";
import DropdownMenuItem from "../../DropdownMenuItem";
import User from "../../../api/User";
import { toast } from "react-toastify";
import Notification from "../../../api/Notification";
import { controlSidebar } from "../../../assets/images";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import ProfileCard from "../../atoms/Cards/ProfileCard";
import RBAC from "../../../api/RBAC";
import Payment from "../../../api/Payment";
import { FaAdjust } from "react-icons/fa";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { emitCustomEvent } from "react-custom-events";

const BusinessSidebar = ({ isEnterprise = true, isMobile }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  const mode = useSelector(darkmode);
  const roles = useSelector(selectRoles);
  const hasAnalytics = useSelector(isAnalytics);
  const allLocations = useSelector(locations);
  const roleStatus = roles.length > 0 ? roles[0] : "";
  const user = useSelector(selectUser);
  const getRole = useSelector(roleName);
  const selectedLocation = useSelector(selectSelectedLocation);
  const proprietor = useSelector(selectProprietor);
  const [displayAddress, setDisplayAddress] = useState(true);
  const [notificationCount, setNotificationCount] = useState(0);
  const domainFLag = process.env.REACT_APP_API_BASE_URL.search("stagingapi");
  const [locationData, setLocationData] = useState([]);
  const showLocationDropdown =
    (isEnterprise && roles[0] !== "employee") ||
    roles[0] === "super-user" ||
    roles[0] === "regional_manager";
  const showAddress = pathname === "/business/dashboard";
  const showExitLocation =
    (isEnterprise && roles[0] === "owner") || roles[0] === "regional_manager";
  const [openSubmenus, setOpenSubmenus] = useState({});
  const openSidebar = useSelector(sidebar);
  const [isTreeVisible, setIsTreeVisible] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const [menuTop, setMenuTop] = useState(0);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const subMenuRef = useRef(null);
  const subRef = useRef(null);
  const menuItemRefs = useRef({});
  const [subMenuItem, setSubMenuItem] = useState(null);
  const [theme, setTheme] = useState(
    () => localStorage.getItem("theme") || "device"
  );
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const [logoutConfirmationModal, setLogoutConfirmationModal] = useState(false);

  useEffect(() => {
    const root = window.document.documentElement;
    if (theme === "device") {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
        .matches
        ? "dark"
        : "light";
      root.classList.toggle("dark", systemTheme === "dark");
      dispatch(setBackgroundcolor(systemTheme === "light"));
    } else {
      root.classList.toggle("dark", theme === "dark");
      dispatch(setBackgroundcolor(theme === "light"));
    }

    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (subRef.current && !subRef.current.contains(event.target)) {
        setIsSubMenuVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [subRef]);

  useEffect(() => {
    if (isMenuVisible && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      setMenuTop(buttonRect.top);
    }
  }, [isMenuVisible]);

  useEffect(() => {
    if (isSubMenuVisible && subMenuRef.current) {
      const buttonRect = subMenuRef.current.getBoundingClientRect();
      setMenuTop(buttonRect.top);
    }
  }, [isSubMenuVisible]);

  const handleLogout = () => {
    navigate("/login");
    dispatch(logout());
  };

  const handleClick = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const handleIconClick = () => {
    setIsTreeVisible(!isTreeVisible);
  };
  const [role, setRole] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkRole = async () => {
    try {
      setLoading(true);

      const info = await Payment.getStripeCustomerID(
        user?.company_id,
        user?.location_id
      );

      if (info) {
        const response = await Payment.getSubscriptionDetailsFromCustomerId(
          info?.data?.stripe_customer?.stripe_customer_id
        );
        if (response?.data?.length >= 1) {
          RBAC.getLocationPlan()
            .then((response) => {
              setRole(response?.data?.data?.name);
              dispatch(setRoleName(response?.data?.data?.name));
            })
            .catch((error) =>
              console.log("uploadApplicationEvent -> error", error)
            );
        } else {
          navigate("/subscription");
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("Error checking roles:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      if (getRole === null) {
        checkRole();
      } else {
        setRole(getRole);
      }
    }
  }, [user, getRole]);

  const HandleSidebar = () => {
    dispatch(setSidebarOpen(!openSidebar));
  };

  useEffect(() => {
    if (isMobile) {
      dispatch(setSidebarOpen(false));
    }
  }, []);

  const loadLocations = async () => {
    const _selectedLocation = await JSON.parse(
      localStorage.getItem("selectedLocation")
    );
    Location.getAll().then((response) => {
      if (response.data.data) {
        const resLocations = response.data.data;
        setLocationData(resLocations);
        if (resLocations.length > 0 && !selectedLocation) {
          if (showLocationDropdown) {
            if (_selectedLocation) {
              dispatch(setSelectedLocation(_selectedLocation));
            } else {
              if (roles[0] === "turnkey_admin") {
              } else {
                dispatch(setSelectedLocation({ name: "Select A Location" }));
              }
            }
          } else {
            dispatch(setSelectedLocation(resLocations[0]));
          }
        }
      }
    });
  };

  const loadUsers = () => {
    User.getAll().then((response) => {
      const propriter = response?.data?.data?.filter((e) => {
        if (e.roles[0]?.name === "owner") {
          return e;
        }
      });
      dispatch(setProprietor(roles[0] !== "super-user" ? propriter[0] : user));
    });
  };

  useEffect(() => {
    loadUsers();
    loadLocations();
    // getUnreadNotificationCount();
  }, []);

  const getUnreadNotificationCount = async () => {
    const response = await Notification.getUnreadCount();
    setNotificationCount(response.data.unread_count);
  };

  const itemsFilteredByPermissions = () => {
    const filteredItems = [];
    items.forEach((i) => {
      if (i.hasOwnProperty("permissions") && i.permissions.length > 0) {
        const permission = i.permissions[0];
        if (permissions.includes(permission)) {
          // Check if item has subItems
          if (i.hasOwnProperty("subItems")) {
            const copiedItem = { ...i };
            copiedItem.subItems = [];
            i.subItems.forEach((subItem) => {
              if (
                subItem.hasOwnProperty("permissions") &&
                subItem.permissions.length > 0
              ) {
                if (permissions.includes(subItem.permissions[0])) {
                  copiedItem.subItems.push(subItem);
                }
              } else {
                copiedItem.subItems.push(subItem);
              }
            });
            filteredItems.push(copiedItem);
          } else {
            filteredItems.push(i);
          }
        }
      } else {
        filteredItems.push(i);
      }
    });

    return filteredItems;
  };

  const items = [
    {
      label: t("sidebar.dashboard"),
      icon: "far fa-home",
      link: "/dashboard",
    },
    {
      label: "My Company",
      icon: "far fa-briefcase",
      link: "/business/my-company",
      permissions: ["company.view"],
      subItems: [
        {
          label: t("sidebar.regions"),
          link: "/companies/regions",
          permissions: ["region.view"],
        },
        {
          label: t("sidebar.locations"),
          link: "/companies/locations",
          permissions: ["location.view"],
        },
        {
          label: t("sidebar.brands"),
          link: "/companies/brands",
          permissions: ["brand.view"],
        },
      ],
    },
    {
      label: t("dashboard.applications"),
      icon: "far fa-folders",
      link: "/business/applications",
    },
    {
      label: "Business Profile",
      icon: "far fa-address-card",
      link: "/business/public-profile",
      subItems: [
        {
          label: "My Business Profile",
          link: "/business/public-profile/my-profile",
        },
        { label: "QR Code", link: "/business/public-profile/qr-code" },
        { label: "Embed in page", link: "/business/public-profile/embed-code" },
      ],
    },
    {
      label: "Billing Info",
      icon: "far fa-money-check",
      link: "/business/payments",
      permissions: ["job_seeker.view"],
    },
    {
      label: "Message Templates",
      icon: "far fa-comment-alt",
      link: "/business/messages/canned-messages",
      permissions: ["chat.view"],
      // subItems: [
      //   { label: t("sidebar.messages"), link: "/messages" },
      //   {
      //     label: t("sidebar.pre-canned-messages"),
      //     link: "/business/messages/canned-messages",
      //   },
      // ],
    },
    {
      label: "Settings",
      icon: "far fa-cogs",
      link: "/business/settings",
    },
  ];

  // TODO: Add appropriate translations
  const businessOwnerItems = [
    {
      label: t("sidebar.dashboard"),
      icon: "far fa-home",
      link: "/dashboard",
    },
    {
      label: "Job Postings",
      icon: "far fa-folders",
      link: "/job-positions",
      subItems: [
        // {
        //   label: "Position Templates",
        //   icon: "far fa-folder",
        //   link: "/predefined-jobs",
        // },
        {
          label: "Manage Positions",
          icon: "far fa-folders",
          link: "/job-positions",
        },
      ],
    },
    // {
    //   label: "Shifts",
    //   icon: "far fa-folders",
    //   link: "/shifts",
    //   subItems: [
    //     {
    //       label: "Post New Shift",
    //       icon: "far fa-folder",
    //       link: "/shifts",
    //     },
    //     {
    //       label: "Active Shifts",
    //       icon: "far fa-folders",
    //       // link: "/job-positions",
    //     },
    //     {
    //       label: "Expired Shifts",
    //       icon: "far fa-folder",
    //       // link: "/predefined-jobs",
    //     },
    //   ],
    // },
    {
      label: "Message Templates",
      icon: "far fa-comment-alt",
      link: "/business/messages/canned-messages",
      // subItems: [
      //   { label: "Inbox", link: "/messages" },
      //   {
      //     label: "Message Templates",
      //     link: "/business/messages/canned-messages",
      //   },
      // ],
    },
    // {
    //   label: "Notifications",
    //   icon: "far fa-bell",
    //   link: "/notifications",
    // },
    {
      label: "Analytics Dashboard",
      icon: "far fa-chart-pie",
      link: "/analytics",
    },
    {
      label: "Posters & QR Codes",
      icon: "far fa-qrcode",
      link: "/business/qr",
      subItems: [
        { label: "Posters", link: "/business/posters" },
        { label: "QR Codes", link: "/business/qr-code" },
      ],
    },
    {
      icon: "far fa-address-card",
      label: "Business Profile",
      link: "/business/public-profile/my-profile",
      subItems: [
        {
          label: "Embed Code",
          icon: "far fa-code",
          link: "/business/public-profile/embed-code",
        },
      ],
    },
    {
      label: "My Account",
      icon: "far fa-user",
      link: "/business/public-profile",
      subItems: [
        {
          label: "Billing Info",
          link: "/business/billing",
        },
      ],
    },
    {
      label: "Users & Permissions",
      icon: "far fa-users",
      link: "/business/manage-users",
    },
    // {
    //   label: "Intergrations",
    //   link: "/intergrations",
    // },
    {
      label: "Help",
      icon: "far fa-question-circle",
      link: "/support",
    },
  ];

  const regionalManagerItems = [
    {
      label: t("sidebar.dashboard"),
      icon: "far fa-home",
      link: "/dashboard",
    },
    {
      label: "Job Postings",
      icon: "far fa-folders",
      link: "/job-positions",
      subItems: [
        // {
        //   label: "Position Templates",
        //   icon: "far fa-folder",
        //   link: "/predefined-jobs",
        // },
        {
          label: "Manage Positions",
          icon: "far fa-folders",
          link: "/job-positions",
        },
      ],
    },
    // {
    //   label: "Shifts",
    //   icon: "far fa-folders",
    //   link: "/shifts",
    //   subItems: [
    //     {
    //       label: "Post New Shift",
    //       icon: "far fa-folder",
    //       link: "/shifts",
    //     },
    //     {
    //       label: "Active Shifts",
    //       icon: "far fa-folders",
    //       // link: "/job-positions",
    //     },
    //     {
    //       label: "Expired Shifts",
    //       icon: "far fa-folder",
    //       // link: "/predefined-jobs",
    //     },
    //   ],
    // },
    {
      label: "Message Templates",
      icon: "far fa-comment-alt",
      link: "/business/messages/canned-messages",
      // subItems: [
      //   // { label: "Inbox", link: "/messages" },
      //   {
      //     label: "Message Templates",
      //     link: "/business/messages/canned-messages",
      //     permissions: ["chat.view"],
      //   },
      // ],
    },
    {
      label: "Notifications",
      icon: "far fa-bell",
      link: "/notifications",
    },
    {
      label: "Analytics Dashboard",
      icon: "far fa-chart-pie",
      link: "/analytics",
    },
    {
      label: "Posters & QR Codes",
      icon: "far fa-qrcode",
      link: "/business/qr",
      subItems: [
        { label: "Posters", link: "/business/posters" },
        { label: "QR Codes", link: "/business/qr-code" },
      ],
    },
    {
      icon: "far fa-address-card",
      label: "Business Profile",
      link: "/business/public-profile/my-profile",
      subItems: [
        {
          label: "Embed Code",
          icon: "far fa-code",
          link: "/business/public-profile/embed-code",
        },
      ],
    },
    {
      label: "My Account",
      icon: "far fa-user",
      link: "/business/public-profile",
      subItems: [
        {
          label: "Billing Info",
          link: "/business/billing",
        },
      ],
    },
    {
      label: "Users & Permissions",
      icon: "far fa-users",
      link: "/business/sub-users",
    },
    // {
    //   label: "Intergrations",
    //   link: "/intergrations",
    // },
    {
      label: "Help",
      icon: "far fa-question-circle",
      link: "/support",
    },
  ];

  const singleBusinessOwnerItems = [
    {
      label: t("sidebar.dashboard"),
      icon: "far fa-home",
      link: "/dashboard",
    },
    {
      label: "Job Postings",
      icon: "far fa-folders",
      link: "/job-positions",
      subItems: [
        {
          label: t("job-positions.active-positions"),
          icon: "far fa-folders",
          link: "/job-positions",
        },
        {
          label: "Archived Positions",
          icon: "far fa-folders",
          link: "/archived-jobs",
        },
      ],
    },
    // {
    //   label: "Shifts",
    //   icon: "far fa-calendar",
    //   link: "/shifts",
    //   subItems: [
    //     {
    //       label: "Post New Shift",
    //       icon: "far fa-folder",
    //       link: "/shifts",
    //     },
    //     {
    //       label: "Active Shifts",
    //       icon: "far fa-folders",
    //       // link: "/job-positions",
    //     },
    //     {
    //       label: "Expired Shifts",
    //       icon: "far fa-folder",
    //       // link: "/predefined-jobs",
    //     },
    //   ],
    // },
    {
      label: "Message Templates",
      icon: "far fa-comment-alt",
      link: "/business/messages/canned-messages",
      // subItems: [
      //   // { label: "Inbox", link: "/messages" },
      //   {
      //     label: "Message Templates",
      //     link: "/business/messages/canned-messages",
      //   },
      // ],
    },
    {
      label: "Notifications",
      icon: "far fa-bell",
      link: "/notifications",
    },
    {
      label: "Analytics Dashboard",
      icon: "far fa-chart-pie",
      link: "/analytics",
    },
    {
      label: "Posters & QR Codes",
      icon: "far fa-qrcode",
      link: "/business/qr",
      subItems: [
        { label: "Posters", link: "/business/posters" },
        { label: "QR Codes", link: "/business/qr-code" },
      ],
    },
    {
      icon: "far fa-address-card",
      label: "Business Profile",
      link: "/business/public-profile/my-profile",
      subItems: [
        {
          icon: "far fa-address-card",
          label: "View Business Profile",
          link: "/business/public-profile/my-profile",
        },
        {
          label: "Edit Business Profile",
          link: "/business/public-profile/edit",
        },
        {
          label: "Embed Code",
          icon: "far fa-code",
          link: "/business/public-profile/embed-code",
        },
      ],
    },
    {
      label: "My Account",
      icon: "far fa-user",
      link: "/business/my-account",
      subItems: [
        {
          label: "Account Information",
          icon: "far fa-user",
          link: "/business/my-account",
        },
        {
          label: "Billing Info",
          icon: "far fa-wallet",
          link: "/payments",
        },
      ],
    },
    {
      label: "Users & Permissions",
      icon: "far fa-users",
      link: "/business/sub-users",
    },
    // {
    //   label: "Intergrations",
    //   link: "/intergrations",
    // },
    {
      label: "Help",
      icon: "far fa-question-circle",
      link: "/support",
    },
  ];

  const turnkeyItems = [
    {
      label: t("sidebar.Settings"),
      link: "/settings",
      permissions: ["settings.view"],
    },
  ];

  const superUserItems = [
    // TODO: Check and replace by translation
    {
      label: t("sidebar.dashboard"),
      icon: "far fa-home",
      link: "/dashboard",
    },
    {
      label: "Manage Businesses",
      icon: "far fa-briefcase",
      // link: "/companies",
      permissions: ["company.view"],
      subItems: [
        {
          label: t("sidebar.companies"),
          link: "/companies",
          permissions: ["company.view"],
        },
        {
          label: t("sidebar.regions"),
          link: "/companies/regions",
          permissions: ["region.view"],
        },
        {
          label: t("sidebar.locations"),
          link: "/companies/locations",
          permissions: ["location.view"],
        },
        {
          label: t("sidebar.brands"),
          link: "/companies/brands",
          permissions: ["brand.view"],
        },
        { label: t("sidebar.address-book"), link: "/admin/address-book" },
        {
          label: t("sidebar.Settings"),
          link: "/settings",
          permissions: ["company.view"],
        },
      ],
    },
    // {
    //   label: "Billing Info",
    //   icon: "far fa-money-check",
    //   link: "/payments",
    //   permissions: ["job_seeker.view"],
    //   subItems: [
    //     { label: "Activity", link: "/payments/activity" },
    //     { label: "Discounts", link: "/payments/discounts" },
    //     { label: "Free Accounts", link: "/payments/free-accounts" },
    //     { label: "Trial Accounts", link: "/payments/trial-accounts" },
    //   ],
    // },
    {
      label: "Manage Users",
      icon: "far fa-user",
      subItems: [
        {
          label: t("sidebar.manage-business-users"),
          link: "/admin/manage-business-users",
        },
        {
          label: t("sidebar.job-seekers"),
          link: "/job-seekers",
          permissions: ["job_seeker.view"],
        },
      ],
    },
    {
      label: "Manage Miscellaneous", // t("sidebar.admin"),
      icon: "far fa-cogs",
      link: "/admin",
      subItems: [
        {
          label: t("sidebar.predefined-jobs"),
          link: "/predefined-jobs",
          permissions: ["predefined_job.view"],
        },
        {
          label: t("sidebar.pre-canned-messages"),
          link: "/messages/canned-messages",
        },
        {
          label: t("sidebar.general-settings"),
          link: "/admin/general-settings",
        },

        // { label: 'Manage Sub Users', link: "/admin/manage-sub-users" },
        // { label: t("sidebar.address-book"), link: "/admin/address-book" },
        { label: t("sidebar.certifications"), link: "/admin/certifications" },
      ],
    },
    {
      label: "Admin Control Panel",
      icon: "far fa-wrench",
      link: "/control-panel",
    },
  ];

  const goItems = [
    {
      label: t("sidebar.dashboard"),
      icon: "far fa-home",
      link: "/dashboard",
    },
    {
      label: "Message Templates",
      icon: "far fa-comment-alt",
      link: "/business/messages/canned-messages",
    },
    {
      label: "Notifications",
      icon: "far fa-bell",
      link: "/notifications",
    },
    {
      label: "Posters & QR Codes",
      icon: "far fa-qrcode",
      link: "/business/qr",
      subItems: [
        { label: "Posters", link: "/business/posters" },
        { label: "QR Codes", link: "/business/qr-code" },
      ],
    },
    {
      icon: "far fa-address-card",
      label: "Business Profile",
      link: "/business/public-profile/my-profile",
      subItems: [
        {
          icon: "far fa-address-card",
          label: "View Business Profile",
          link: "/business/public-profile/my-profile",
        },
        {
          label: "Edit Business Profile",
          link: "/business/public-profile/edit",
        },
        {
          label: "Embed Code",
          icon: "far fa-code",
          link: "/business/public-profile/embed-code",
        },
      ],
    },
    {
      label: "My Account",
      icon: "far fa-user",
      link: "/business/my-account",
      subItems: [
        {
          label: "Account Information",
          icon: "far fa-user",
          link: "/business/my-account",
        },
        {
          label: "Billing Info",
          icon: "far fa-wallet",
          link: "/payments",
        },
      ],
    },
    {
      label: "Support",
      icon: "far fa-envelope",
      link: "/support",
    },
  ];

  const onClickItem = (link) => {
    if (link === "/business/public-profile/my-profile") {
      window.open(
        `https://${domainFLag !== -1 ? "staging.heyhire" : "heyhire"}.app/${
          selectedLocation?.brand?.url_slug
        }/${selectedLocation?.url_slug}`
      );
    }
    // else if (link === '/support') {
    //   console.log('/support -> /support');
    //   window.Intercom('boot', {
    //     app_id: 'xq0ow4gh'
    //   });
    // }
    else {
      navigate(link);
    }
    // emitCustomEvent("toggle-sidebar");
  };

  const UserInfo = () => {
    return (
      <>
        <ProfileCard
          header={
            openSidebar
              ? roles[0] === "turnkey_admin"
                ? "Turnkey Account"
                : roles[0] === "super-user"
                ? "HeyHire Admin"
                : selectedLocation?.name === "Select A Location"
                ? user?.company?.name
                : selectedLocation?.name
              : selectedLocation?.name
          }
          modifiedheader={
            openSidebar
              ? roles[0] === "turnkey_admin"
                ? "Turnkey Account"
                : roles[0] === "super-user"
                ? "HeyHire Admin"
                : selectedLocation?.name === "Select A Location"
                ? user?.company?.name
                : selectedLocation?.name
              : ""
          }
          content={
            openSidebar
              ? `
                  ${
                    roles[0] === "turnkey_admin"
                      ? "Proprietor: Turnkey Account"
                      : proprietor?.first_name !== null &&
                        proprietor?.last_name !== null
                      ? `${
                          proprietor?.first_name !== undefined
                            ? `${proprietor?.first_name}`
                            : ""
                        } ${
                          proprietor?.last_name !== undefined
                            ? `${proprietor?.last_name}`
                            : ""
                        }`
                      : " - "
                  }`
              : ""
          }
          location={
            openSidebar
              ? `
                  ${
                    roles[0] === "turnkey_admin"
                      ? "Proprietor: Turnkey Account"
                      : selectedLocation !== null
                      ? `${
                          selectedLocation?.address?.address !== undefined
                            ? `${selectedLocation?.address?.address}`
                            : ""
                        }`
                      : " - "
                  }`
              : ""
          }
          imageUrl={
            selectedLocation?.brand?.photo?.thumb_url || defaultUserImage
          }
          imageClass={
            openSidebar
              ? "h-20 rounded-full object-fill"
              : "h-14 rounded-full object-fill"
          }
          tooltipText={openSidebar ? false : true}
          sidebar={openSidebar}
          role={roleStatus}
          selectedLocation={selectedLocation}
        />

        {/* {showAddress &&
          displayAddress &&
          roles[0] === "super-user" &&
          selectedLocation?.id && (
            <div className="my-4 bg-primary-400 rounded-md p-4">
              <p className="w-48 font-semibold text-white text-sm">
                {selectedLocation?.address?.address +
                  ", " +
                  selectedLocation?.address?.city +
                  ", " +
                  selectedLocation?.address?.state || " "}
              </p>
            </div>
          )}
        {roles[0] !== "turnkey_admin" &&
          selectedLocation?.id &&
          showExitLocation && (
            <div className="relative mt-6 w-full">
              <div className="p-3 w-full rounded bg-white/10 flex justify-center text-white cursor-pointer my-4">
                <button
                  onClick={() => {
                    localStorage.removeItem("selectedLocation");
                    dispatch(
                      setSelectedLocation({ name: "Select A Location" })
                    );
                    navigate(`/dashboard`);
                  }}
                >
                  <span class="block leading-tight font-normal w-full">
                    Exit Managing Location
                  </span>
                </button>
              </div>
            </div>
          )} */}
      </>
    );
  };

  const changeLocation = async (id) => {
    try {
      await User.updateLocation(id);
      toast.success("Location Changed!");
    } catch (error) {
      toast.error("Error Occurred during Location switching. Try Again!");
    }
  };

  const menu = (
    <div
      className="z-10 origin-top-right w-64 h-auto md:max-h-[600px] lg:max-h-[600px] max-h-[400px] rounded-md shadow-lg
       bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div
        className="py-1 h-auto md:max-h-[600px] lg:max-h-[600px] max-h-[400px] overflow-auto"
        role="none"
      >
        {roles[0] !== "turnkey_admin" &&
          locationData?.map((location, index) => (
            <DropdownMenuItem
              key={location?.id}
              onClick={() => {
                dispatch(setSelectedLocation(location));
                localStorage.setItem(
                  "selectedLocation",
                  JSON.stringify(location)
                );
                changeLocation(location?.id);
                if (roles[0] !== "super-user") {
                  setDisplayAddress(false);
                }
                navigate(`/business/dashboard?location=${location?.id}`);
              }}
              label={location?.name}
              icon="far fa-map-marker-alt"
            />
          ))}
      </div>
    </div>
  );

  const BusinessLocation = ({ className }) => {
    // if (roles[0] !== "super-user" && selectedLocation?.id) return <></>;
    return (
      <div className="relative mx-1">
        <div
          className={
            className +
            ` rounded-xl bg-primary-500 dark:bg-[#1C1D1F] flex ${
              openSidebar ? "p-4" : "p-3 cursor-pointer"
            }`
          }
          data-tooltip-id={`sidebar-id`}
          data-tooltip-content={user?.company?.name}
          data-tooltip-place="right"
          onClick={() => handleClick()}
          ref={buttonRef}
        >
          <img
            src={locationWhiteIcon}
            alt="logo"
            className={`my-auto ${
              openSidebar ? "w-8 h-8 mr-2.5" : "w-6 h-6 mx-auto"
            }`}
          />
          <div className={`${openSidebar ? "flex flex-col" : "hidden"}`}>
            <p className="font-normal text-white text-xs">Currently Viewing</p>
            <p className="font-bold text-white text-sm">
              {selectedLocation?.name === "Select A Location"
                ? "Main Dashboard"
                : selectedLocation?.name}
            </p>
          </div>
          <div
            className={`${
              openSidebar ? "flex items-center ml-auto" : "hidden"
            }`}
          >
            <img
              src={arrowDownWhiteIcon}
              alt="logo"
              className="my-auto w-4 h-4 cursor-pointer"
              onClick={handleIconClick}
            />
          </div>
        </div>
        {isTreeVisible && openSidebar && (
          <LocationTree locations={locationData} />
        )}
      </div>
    );
  };

  const LocationTree = ({ locations }) => {
    return (
      <div className="mt-2">
        {locations
          ?.filter((location) => location.name !== selectedLocation?.name)
          .map((location) => (
            <div className="flex flex-col gap-3 relative ml-[47px]">
              <div className="flex flex-col gap-1 relative flex-[0_0_auto]">
                <div
                  className="gap-[5px] px-3 py-2 flex items-center relative flex-[0_0_auto] rounded-lg hover:bg-warmgray-200 dark:hover:bg-darkGray"
                  onClick={() => {
                    dispatch(setSelectedLocation(location));
                    localStorage.setItem(
                      "selectedLocation",
                      JSON.stringify(location)
                    );
                    changeLocation(location?.id);
                    navigate(`/business/dashboard?location=${location?.id}`);
                    dispatch(setInitialChatFetch(false));
                    dispatch(setVisiblePersons([]));
                    dispatch(setMinimizedPersons([]));
                    dispatch(setSelectedPersons([]));
                  }}
                >
                  <img
                    className="relative w-5 h-5 mr-1"
                    alt="Vector"
                    src={mode ? locationTag1Icon : locationWhiteIcon}
                  />
                  <div className="font-medium tracking-[-0.24px] leading-4 relative text-sm cursor-pointer text-gray-900 dark:text-gray-300">
                    {location?.name}
                  </div>
                  <div className="absolute w-[13px] h-2 top-3 left-[-13px] rounded-[0px_0px_0px_8px] border-b-2 [border-bottom-style:solid] border-l-2 [border-left-style:solid] border-warmgray-200" />
                </div>
                <div
                  className="absolute left-[-13px] w-0.5 bg-[#f6f6f6] border border-solid border-warmgray-200"
                  style={{ height: "100%", top: 0 }}
                />
              </div>
            </div>
          ))}

        {selectedLocation.name !== "Select A Location" && (
          <div className="flex flex-col gap-3 relative ml-[47px]">
            <div className="flex flex-col gap-1 relative flex-[0_0_auto]">
              <div
                className="gap-[5px] px-3 py-2 flex items-center relative flex-[0_0_auto] rounded-lg cursor-pointer"
                onClick={() => {
                  localStorage.removeItem("selectedLocation");
                  dispatch(
                    setSelectedLocation({
                      name: "Select A Location",
                    })
                  );
                  navigate(`/dashboard`);
                  dispatch(setInitialChatFetch(false));
                  dispatch(setVisiblePersons([]));
                  dispatch(setMinimizedPersons([]));
                  dispatch(setSelectedPersons([]));
                }}
              >
                <div className="font-medium tracking-[-0.24px] leading-4 relative text-sm text-gray-900 dark:text-white">
                  Return to Main Dashboard
                </div>
                <div className="absolute w-[13px] h-2 top-3 left-[-13px] rounded-[0px_0px_0px_8px] border-b-2 [border-bottom-style:solid] border-l-2 [border-left-style:solid] border-warmgray-200" />
              </div>
            </div>
            <div
              className="absolute left-[-13px] w-0.5 bg-[#f6f6f6] border border-solid border-warmgray-200"
              style={{ height: "100%", top: 0 }}
            />
          </div>
        )}

        <p className="text-xs text-center font-medium mt-4 text-gray-900 dark:text-white">
          Select Location to manage
        </p>
      </div>
    );
  };

  const itemsByRoles = () => {
    if (role === "Heyhire Go") {
      return goItems;
    } else if (!loading) {
      const role = roles[0];
      switch (role) {
        case "super-user":
          return superUserItems;
        case "turnkey_admin":
          return turnkeyItems;
        case "owner":
          if (!isEnterprise || selectedLocation?.id) {
            /*
          const item = {
            label: "Manage Positions",
            icon: "far fa-folders",
            link: "/job-positions",
          };
          */
            let dataCopy = singleBusinessOwnerItems;
            // dataCopy.splice(2, 0, item);
            return dataCopy;
          }
          return businessOwnerItems;
        case "regional_manager":
          if (selectedLocation?.id) {
            const item = {
              label: "Manage Positions",
              icon: "far fa-folders",
              link: "/job-positions",
            };
            let dataCopy = singleBusinessOwnerItems;
            dataCopy.splice(2, 0, item);
            return dataCopy;
          }
          return regionalManagerItems;
        case "employee":
          const item = {
            label: "Account Information",
            icon: "far fa-user",
            link: "/business/my-account",
          };
          const dataCopy = singleBusinessOwnerItems.slice();
          dataCopy.splice(
            singleBusinessOwnerItems.findIndex(
              (item) => item?.label === "Users & Permissions"
            ),
            1
          );
          dataCopy.splice(
            singleBusinessOwnerItems.findIndex(
              (item) => item?.label === "My Account"
            ),
            1
          );
          dataCopy.splice(6, 0, item);
          return dataCopy;
        default:
          return singleBusinessOwnerItems;
      }
    }
  };

  const handleMenuClick = () => {
    if (openSidebar) {
      setOpenSubmenu(!openSubmenu);
    } else {
      const menuItemRef = menuItemRefs.current["Theme"];
      if (menuItemRef) {
        const rect = menuItemRef.getBoundingClientRect();
        setMenuTop(rect.top);
      }
      setSubMenuItem("Theme");
      setIsSubMenuVisible(!isSubMenuVisible);
    }
  };

  const handleSubMenuClick = (label) => {
    if (openSidebar) {
      setOpenSubmenus((prevOpenSubmenus) => ({
        ...prevOpenSubmenus,
        [label]: !prevOpenSubmenus[label],
      }));
    } else {
      const menuItemRef = menuItemRefs.current[label];
      if (menuItemRef) {
        const rect = menuItemRef.getBoundingClientRect();
        setMenuTop(rect.top);
      }
      setSubMenuItem(label);
      setIsSubMenuVisible(!isSubMenuVisible);
    }
  };

  const checkLocationAnalytics = () => {
    const hasAnalytics = allLocations?.some(
      (location) => location.include_analytics === 1
    );
    if (hasAnalytics) {
      dispatch(setAnalytics(true));
    }
  };

  useEffect(() => {
    if (allLocations?.length > 0) {
      checkLocationAnalytics();
    }
  }, [allLocations]);

  useEffect(() => {
    if (!openSidebar) {
      setOpenSubmenus({});
      setSubMenuItem(null);
    } else {
      const updatedOpenSubmenus = { ...openSubmenus };
      itemsByRoles()?.forEach((_item) => {
        if (_item.subItems) {
          const shouldOpen = _item.subItems.some(
            (subItem) => subItem.link === pathname
          );
          if (shouldOpen) {
            updatedOpenSubmenus[_item.label] = true;
          }
        }
      });
      setOpenSubmenus(updatedOpenSubmenus);
    }
  }, [openSidebar]);

  useEffect(() => {
    const updateOpenSubmenus = () => {
      const updatedOpenSubmenus = {};
      itemsByRoles()?.forEach((_item) => {
        if (_item.subItems) {
          const shouldOpen = _item.subItems.some(
            (subItem) => subItem.link === pathname
          );
          updatedOpenSubmenus[_item.label] = shouldOpen;
        }
      });
      setOpenSubmenus(updatedOpenSubmenus);
    };
    updateOpenSubmenus();
  }, [pathname]);

  const isItemActiveInSubmenu = (_item) => {
    if (!_item.subItems) {
      return false; // Item has no submenus, so it can't be active in one.
    }
    return _item.subItems.some((subItem) => subItem.link === pathname);
  };

  return (
    <>
      <ConfirmationModal
        openTrigger={logoutConfirmationModal}
        closeTrigger={() => setLogoutConfirmationModal(false)}
        type="error"
        title="Confirm Logout"
        confirmText="Confirm"
        description="Are you sure you want to log out? You will be signed out of the application."
        confirm={handleLogout}
      />

      <div>
        <img
          src={controlSidebar}
          className={`absolute cursor-pointer right-[-20px] top-12 lg:w-10 z-50 ${
            !openSidebar && "rotate-180"
          }`}
          onClick={HandleSidebar}
        />
      </div>

      <div className="p-2 w-full flex flex-col border-r rounded-tr-none dark:border-darkGray rounded-br-3xl border-gray-200 h-full bg-white dark:bg-[#242526] business-sidebar">
        <div className={`flex flex-col mb-5 mt-1 p-2 ${sidebar ? "mt-5" : ""}`}>
          <div className="flex flex-col flex-1">
            <div
              className={`flex-1 flex flex-col scroll-custom ${
                openSidebar ? "" : "items-center"
              }`}
            >
              <UserInfo />
            </div>
          </div>
        </div>

        {openSidebar && (
          <hr className="mb-4 border-[#F6F6F6] dark:border-warmgray-200/10 border-2" />
        )}

        <div className="overflow-y-auto px-2 h-full flex flex-col">
          {roles[0] !== "turnkey_admin" && showLocationDropdown && (
            <>
              <BusinessLocation
                className={`relative w-full ${openSidebar ? "" : "mt-4"}`}
              />
              <hr className="border-[#F6F6F6] dark:border-warmgray-200/10 border-2 my-4" />
            </>
          )}

          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col">
              <li>
                <ul
                  role="list"
                  className={`${openSidebar ? "space-y-2" : "space-y-3"}`}
                >
                  {loading && (
                    <div class="flex flex-col gap-4">
                      {Array.from({ length: 7 }).map((_, index) => (
                        <div className="rounded-2xl max-w-sm">
                          <div className="animate-pulse flex space-x-4 h-12 bg-warmgray-200 dark:bg-[#363739] rounded-lg"></div>
                        </div>
                      ))}
                    </div>
                  )}
                  {itemsByRoles()?.map((_item, _i) => {
                    if (
                      _item.label === "Analytics Dashboard" &&
                      !hasAnalytics
                    ) {
                      return null;
                    }
                    return (
                      <li key={_item.label}>
                        {_item.label === "Support" && openSidebar ? (
                          <hr className="mx-4 my-3 border-warmgray-300" />
                        ) : null}
                        {!_item.subItems ? (
                          <div
                            onClick={(event) => {
                              event.preventDefault();
                              if (
                                roles[0] !== "turnkey_admin" &&
                                showLocationDropdown &&
                                _item.label === "Dashboard" &&
                                selectedLocation?.name !== "Select A Location"
                              ) {
                                onClickItem(`/business/dashboard`); // Navigate to location-specific dashboard
                              } else {
                                onClickItem(_item?.link); // Default for other menu items
                              }
                            }}
                            className={`
                             ${
                               (pathname === "/business/dashboard" &&
                                 _item.label === "Dashboard") ||
                               pathname === _item.link
                                 ? "bg-primary-500 dark:bg-[#1C1D1F] text-white"
                                 : "text-[#304253] dark:text-white hover:bg-warmgray-200 dark:hover:bg-darkGray"
                             }
                            flex items-center gap-x-3 p-2 sidebar-menu rounded-lg mx-1 cursor-pointer ${
                              openSidebar ? "flex-row" : "flex-col"
                            }`}
                            data-tooltip-id={`sidebar-id`}
                            data-tooltip-content={_item.label}
                            data-tooltip-place="right"
                          >
                            {_item.label === "Intergrations" ? (
                              <img
                                src={
                                  pathname === _item.link
                                    ? intergrationsWhiteIcon
                                    : intergrationsIcon
                                }
                                alt="Intergrations"
                                className="min-w-[30px] h-[17px] w-[17px]"
                              />
                            ) : (
                              <i
                                className={`min-w-[30px] ${
                                  (pathname === "/business/dashboard" &&
                                    _item.label === "Dashboard") ||
                                  pathname === _item.link
                                    ? "text-white"
                                    : "text-primary-500 dark:text-gray-500"
                                }
                                shrink-0 flex items-center justify-center ${
                                  openSidebar ? " text-lg" : "text-base"
                                } ${_item.icon}`}
                              />
                            )}

                            <div
                              className={`text-center flex items-center whitespace-pre-wrap ${
                                openSidebar ? "" : "text-[10px] hidden"
                              }`}
                            >
                              {_item.label}
                            </div>
                          </div>
                        ) : (
                          <div className="relative">
                            <div
                              className={`${
                                !openSidebar && isItemActiveInSubmenu(_item)
                                  ? "bg-primary-500 dark:bg-[#1C1D1F] text-white"
                                  : "text-[#304253] dark:text-white hover:bg-warmgray-200 dark:hover:bg-darkGray"
                              } flex items-center rounded-lg p-2 gap-x-3 sidebar-menu mx-1 cursor-pointer ${
                                openSidebar
                                  ? "flex-row items-center"
                                  : "flex-col"
                              } ${
                                openSidebar
                                  ? openSubmenus[_item.label] &&
                                    "bg-[#F6F6F6] dark:bg-warmgray-200/10"
                                  : subMenuItem === _item.label &&
                                    "bg-[#F6F6F6] dark:bg-warmgray-200/10"
                              }`}
                              onClick={() => handleSubMenuClick(_item.label)}
                              data-tooltip-id={`sidebar-id`}
                              data-tooltip-content={_item.label}
                              data-tooltip-place="right"
                              ref={(el) =>
                                (menuItemRefs.current[_item.label] = el)
                              }
                            >
                              {_item.label === "Shifts" ? (
                                <img
                                  src={
                                    pathname?.includes("shifts") &&
                                    pathname !== "/shifts/event/add" &&
                                    !openSidebar
                                      ? shiftsWhiteIcon
                                      : shiftsIcon
                                  }
                                  alt="ShiftsImage"
                                  className="min-w-[30px] w-[18px] h-[18px] my-0.5"
                                />
                              ) : (
                                <i
                                  className={`min-w-[30px] ${
                                    isItemActiveInSubmenu(_item)
                                      ? "text-white"
                                      : "text-primary-500 dark:text-gray-500"
                                  } shrink-0 flex items-center justify-center ${
                                    openSidebar ? " text-lg" : "text-base"
                                  } ${_item.icon}`}
                                />
                              )}
                              <div
                                className={`text-center flex items-center whitespace-pre-wrap ${
                                  openSidebar ? "" : "text-[10px] hidden"
                                }`}
                              >
                                {_item.label}
                              </div>
                              {openSidebar && (
                                <ChevronRightIcon
                                  className={`${
                                    openSubmenus[_item.label]
                                      ? "rotate-90 text-[#304253] dark:text-gray-400"
                                      : "text-[#304253] dark:text-gray-400"
                                  }
                                    ml-auto h-5 w-5 shrink-0
                                  `}
                                  aria-hidden="true"
                                />
                              )}
                            </div>
                            {openSidebar && openSubmenus[_item.label] && (
                              <div className="mt-3">
                                {_item.subItems.map((subItem) => (
                                  <div
                                    key={subItem.label}
                                    className="flex flex-col gap-3 relative ml-[40px] mx-1"
                                  >
                                    <div className="flex flex-col gap-1 relative flex-[0_0_auto]">
                                      <div
                                        onClick={(event) => {
                                          event.preventDefault();
                                          onClickItem(subItem.link);
                                        }}
                                        className={`gap-[5px] px-3 py-3 flex items-center relative flex-[0_0_auto] rounded-lg ${
                                          pathname === subItem.link
                                            ? "bg-primary-500 dark:bg-[#1C1D1F] text-white"
                                            : "text-[#304253] dark:text-gray-100 hover:bg-warmgray-200 dark:hover:bg-darkGray"
                                        } cursor-pointer`}
                                      >
                                        <div className="tracking-[-0.24px] leading-4 relative text-sm">
                                          {subItem.label}
                                        </div>
                                        <div className="absolute w-[13px] h-2 top-3 left-[-13px] rounded-[0px_0px_0px_8px] border-b-2 [border-bottom-style:solid] border-l-2 [border-left-style:solid] border-warmgray-200" />
                                      </div>
                                      <div
                                        className="absolute left-[-13px] w-0.5 bg-[#f6f6f6] border border-solid border-warmgray-200"
                                        style={{ height: "100%", top: 0 }}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                        {_i === 2 && openSidebar ? (
                          <hr className="mx-4 my-3 border-warmgray-300" />
                        ) : null}
                      </li>
                    );
                  })}
                </ul>

                <ul
                  role="list"
                  className={`${openSidebar ? "space-y-2" : "space-y-3"}`}
                >
                  <div className="relative">
                    <div
                      className={`${
                        openSubmenu &&
                        openSidebar &&
                        "bg-[#F6F6F6] dark:bg-warmgray-200/10"
                      } hover:bg-warmgray-200 dark:hover:bg-darkGray dark:text-white flex items-center gap-x-3 px-2 py-3 mt-2 sidebar-menu mx-1 cursor-pointer rounded-lg ${
                        openSidebar ? "flex-row" : "flex-col"
                      }`}
                      onClick={handleMenuClick}
                      data-tooltip-id={`sidebar-id`}
                      data-tooltip-content={"Theme"}
                      data-tooltip-place="right"
                      ref={(el) => (menuItemRefs.current["Theme"] = el)}
                    >
                      <i className="min-w-[30px] shrink-0 flex items-center justify-center">
                        <FaAdjust
                          className={`${
                            openSidebar ? " text-lg" : "text-base"
                          } text-primary-500 dark:text-gray-500`}
                        />
                      </i>
                      <div
                        className={`text-center flex items-center whitespace-pre-wrap ${
                          openSidebar ? "" : "text-[10px] hidden"
                        }`}
                      >
                        Theme
                      </div>
                      {openSidebar && (
                        <ChevronRightIcon
                          className={`${
                            openSubmenu
                              ? "rotate-90 text-[#304253] dark:text-gray-400"
                              : "text-[#304253] dark:text-gray-400"
                          } ml-auto h-5 w-5 shrink-0`}
                          aria-hidden="true"
                        />
                      )}
                    </div>

                    {openSidebar && openSubmenu && (
                      <div className="mt-3">
                        {[
                          { label: "Light Mode", theme: "light" },
                          { label: "Dark Mode", theme: "dark" },
                          { label: "System Default", theme: "device" },
                        ].map((subItem) => (
                          <div
                            key={subItem.label}
                            className="flex flex-col gap-3 relative ml-[40px] mx-1"
                          >
                            <div className="flex flex-col gap-1 relative flex-[0_0_auto]">
                              <div
                                onClick={(event) => {
                                  event.preventDefault();
                                  toggleTheme(subItem.theme);
                                }}
                                className={`gap-[5px] px-3 py-3 flex items-center relative flex-[0_0_auto] rounded-lg ${
                                  theme === subItem.theme
                                    ? "bg-primary-500 dark:bg-[#1C1D1F] text-white"
                                    : "text-[#304253] dark:text-gray-100 hover:bg-warmgray-200 dark:hover:bg-darkGray"
                                } cursor-pointer`}
                              >
                                <div className="tracking-[-0.24px] leading-4 relative text-sm">
                                  {subItem.label}
                                </div>
                                <div className="absolute w-[13px] h-2 top-3 left-[-13px] rounded-[0px_0px_0px_8px] border-b-2 [border-bottom-style:solid] border-l-2 [border-left-style:solid] border-warmgray-200" />
                              </div>
                              <div
                                className="absolute left-[-13px] w-0.5 bg-[#f6f6f6] border border-solid border-warmgray-200"
                                style={{ height: "100%", top: 0 }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </ul>

                <ul
                  role="list"
                  className={`${openSidebar ? "space-y-2" : "space-y-3"}`}
                >
                  <div
                    onClick={() => {
                      emitCustomEvent("toggle-sidebar-close");
                      setLogoutConfirmationModal(true);
                    }}
                    className={`text-red-500 hover:bg-warmgray-200 dark:hover:bg-darkGray flex items-center gap-x-3 p-2 mt-2 sidebar-menu mx-1 cursor-pointer rounded-lg ${
                      openSidebar ? "flex-row" : "flex-col"
                    }`}
                    data-tooltip-id={`sidebar-id`}
                    data-tooltip-content={"Logout"}
                    data-tooltip-place="right"
                  >
                    <i
                      className={`min-w-[30px] shrink-0 flex items-center justify-center ${
                        openSidebar ? " text-lg" : "text-base"
                      } far fa-sign-out`}
                    />
                    <div
                      className={`text-center flex items-center whitespace-pre-wrap ${
                        openSidebar ? "" : "text-sm hidden"
                      }`}
                    >
                      Logout
                    </div>
                  </div>
                </ul>
              </li>
            </ul>
          </nav>
          <div
            className={`flex py-10 mt-auto mx-auto justify-center ${
              openSidebar ? "px-4" : "px-2"
            }`}
          >
            {openSidebar ? (
              <img
                width={150}
                src={mode ? heyhireBlackIcon : heyhireIcon}
                alt="Apploy Me"
              />
            ) : (
              <img
                width={150}
                src={mode ? heyhireSidebar : heyhireSidebarWhite}
                alt="Apploy Me"
              />
            )}
          </div>
        </div>
      </div>

      {isMenuVisible && !openSidebar && (
        <div
          ref={menuRef}
          className="absolute left-full min-w-[220px] h-auto bg-white dark:bg-[#2e2f30] shadow-lg z-100 border border-warmgray-300 dark:border-warmgray-600 rounded-xl"
          style={{ top: menuTop }}
        >
          <div className="p-2">
            {locationData?.map((location) => (
              <div
                className={`gap-[5px] px-3 py-2 flex items-center relative flex-[0_0_auto] rounded-lg cursor-pointer ${
                  selectedLocation?.name === location?.name
                    ? "bg-primary-500 dark:bg-[#1C1D1F] text-white"
                    : "hover:bg-warmgray-200 dark:hover:bg-darkGray dark:text-warmgray-300"
                }`}
                onClick={() => {
                  dispatch(setSelectedLocation(location));
                  localStorage.setItem(
                    "selectedLocation",
                    JSON.stringify(location)
                  );
                  changeLocation(location?.id);
                  navigate(`/business/dashboard?location=${location?.id}`);
                  dispatch(setInitialChatFetch(false));
                  dispatch(setVisiblePersons([]));
                  dispatch(setMinimizedPersons([]));
                  dispatch(setSelectedPersons([]));
                }}
              >
                <img
                  className="relative w-5 h-5 mr-1"
                  alt="Vector"
                  src={
                    selectedLocation?.name === location?.name
                      ? locationWhiteIcon
                      : mode
                      ? locationTag1Icon
                      : locationWhiteIcon
                  }
                />
                <div className="font-medium tracking-[-0.24px] leading-4 relative text-[14px] cursor-pointer">
                  {location?.name}
                </div>
              </div>
            ))}
            {selectedLocation?.name !== "Select A Location" && (
              <p
                className="font-bold text-[13px] cursor-pointer my-2 text-center dark:text-gray-300"
                onClick={() => {
                  localStorage.removeItem("selectedLocation");
                  dispatch(
                    setSelectedLocation({
                      name: "Select A Location",
                    })
                  );
                  navigate(`/dashboard`);
                  dispatch(setInitialChatFetch(false));
                  dispatch(setVisiblePersons([]));
                  dispatch(setMinimizedPersons([]));
                  dispatch(setSelectedPersons([]));
                }}
              >
                Return to Main Dashboard
              </p>
            )}
            <p className="text-[11px] text-center font-medium my-2 dark:text-gray-300">
              Select Location to manage
            </p>
          </div>
        </div>
      )}

      {isSubMenuVisible && !openSidebar && (
        <div
          ref={subRef}
          className="absolute left-full min-w-[220px] h-auto bg-white dark:bg-[#2e2f30] shadow-xl z-100 border border-warmgray-300 dark:border-warmgray-600 rounded-xl"
          style={{ top: menuTop }}
        >
          {subMenuItem === "Theme"
            ? [
                { label: "Light Mode", theme: "light" },
                { label: "Dark Mode", theme: "dark" },
                { label: "System Default", theme: "device" },
              ].map((subItem) => (
                <div
                  key={subItem}
                  className={`flex items-center m-2 p-2 cursor-pointer rounded-lg ${
                    subItem.theme === theme
                      ? "bg-primary-500 dark:bg-[#1C1D1F] text-gray-300"
                      : "hover:bg-warmgray-200 dark:hover:bg-darkGray dark:text-warmgray-300"
                  }`}
                  onClick={(event) => {
                    event.preventDefault();
                    toggleTheme(subItem?.theme);
                  }}
                >
                  <span className="text-sm">{subItem.label}</span>
                </div>
              ))
            : itemsByRoles()
                ?.find((_item) => _item.label === subMenuItem && _item.subItems)
                ?.subItems?.map((subItem, subIndex) => (
                  <div
                    key={subIndex}
                    className={`flex items-center m-2 p-2 cursor-pointer rounded-lg ${
                      subItem?.link === pathname
                        ? "bg-primary-500 dark:bg-[#1C1D1F] text-gray-300"
                        : "hover:bg-warmgray-200 dark:hover:bg-darkGray dark:text-warmgray-300"
                    }`}
                    onClick={(event) => {
                      event.preventDefault();
                      onClickItem(subItem.link);
                    }}
                  >
                    <span className="text-sm">{subItem.label}</span>
                  </div>
                ))}
        </div>
      )}
    </>
  );
};

export default BusinessSidebar;
