import React, { useState } from "react";

const Table = ({
  title,
  headers,
  data,
  buttonData,
  mainHeader,
  sortConfig = {},
}) => {
  const [sortedData, setSortedData] = useState(data);
  const [sortState, setSortState] = useState({
    column: null,
    direction: null,
  });

  const getSortIcon = (header) => {
    if (!sortConfig[header]) return null;

    if (sortState.column !== header) {
      return (
        <svg
          className="ml-2 h-4 w-4 inline-block text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
          />
        </svg>
      );
    }

    return sortState.direction === "asc" ? (
      <svg
        className="ml-2 h-4 w-4 inline-block text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 15l7-7 7 7"
        />
      </svg>
    ) : (
      <svg
        className="ml-2 h-4 w-4 inline-block text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M19 9l-7 7-7-7"
        />
      </svg>
    );
  };

  const handleSort = (header) => {
    if (!sortConfig[header]) return;

    const newDirection =
      sortState.column === header && sortState.direction === "asc"
        ? "desc"
        : "asc";
    setSortState({ column: header, direction: newDirection });

    const sorted = React.Children.toArray(data).sort((a, b) => {
      const aValue = sortConfig[header](a);
      const bValue = sortConfig[header](b);

      return newDirection === "asc"
        ? typeof aValue === "string"
          ? aValue.localeCompare(bValue)
          : aValue - bValue
        : typeof aValue === "string"
        ? bValue.localeCompare(aValue)
        : bValue - aValue;
    });

    setSortedData(sorted);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 border border-warmgray-300 dark:border-none rounded-xl shadow-sm bg-white dark:bg-[#242526]">
      {(mainHeader || title || buttonData) && (
        <div className="sm:flex sm:items-center py-4">
          <div className="sm:flex-auto">
            <div className="sm:hidden mb-4 flex justify-end">{buttonData}</div>
            <h1
              className={`${
                mainHeader ? "text-xl xl:text-2xl" : "text-lg"
              } font-bold leading-6 text-gray-900 dark:text-gray-300`}
            >
              {mainHeader ? mainHeader : title}
            </h1>
          </div>
          <div className="hidden sm:block">{buttonData}</div>
        </div>
      )}
      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-warmgray-400">
              <thead>
                <tr>
                  {headers
                    ?.filter(
                      (header) => !["Edit", "Delete", "Action"].includes(header)
                    )
                    .map((header, index) => (
                      <th
                        key={index}
                        scope="col"
                        className={`pr-3 py-3.5 ${
                          header === "Action" ? "text-right" : "text-left"
                        } 
                          text-sm md:text-base font-bold text-gray-900 dark:text-gray-300 text-nowrap 
                          ${sortConfig[header] ? "cursor-pointer" : ""}`}
                        onClick={() => handleSort(header)}
                      >
                        <span className="flex items-center">
                          {header}
                          {getSortIcon(header)}
                        </span>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-warmgray-300">
                {sortedData}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
